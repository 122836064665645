import "./common/css/table.css";

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { fetchPermissions } from "./store/slices/permissionsSlice";
import PublicRoutes from "./routes/PublicRoutes";
import UnauthorizedPage from "./domains/auth/pages/UnauthorizedPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NotFound404 from "./NotFound404";
import { CallGETAPI } from "./common/services";
import { DecryptToken } from "./common/helper";
import PrivateRoutes from "./routes/PrivateRoutes";
import { Spinner } from "react-bootstrap";
import TableSkeleton from "./common/components/Loader/TableSkeleton";
import {
  compareAndUpdateToken,
  pathChangeDecryptToken,
} from "./common/helper/BasicFn";
import { handleClearEquipmentFilter } from "./domains/accounts/components/filter/helperFunction.js/AEDFilterFn";
import {
  removeFilterData,
  removePayloadData,
} from "./store/slices/AccountDetailsEquipmentFilter";

const queryClient = new QueryClient();

// Create a separate component for the main app content
const AppContent = () => {
  const dispatch = useDispatch();
  const user = DecryptToken();
  const location = useLocation();
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const fetchPermissionAPI = async () => {
    try {
      const user = await DecryptToken();
      // const isAdminUser = Number(user?.user_type) === 1;

      // // Remove existing permissions based on user type
      // isAdminUser
      //   ? sessionStorage.removeItem("per")
      //   : localStorage.removeItem("Permission");

      const result = await CallGETAPI("auth/priviledge");
      const permission = JSON.stringify(result?.data?.data?.data);
      const oldToken = await DecryptToken();
      const newToken = await pathChangeDecryptToken(
        result?.data?.data?.refreshtoken
      );
      // console.log({ oldToken }, { newToken });
      const wow = compareAndUpdateToken({
        oldToken: oldToken,
        newToken: newToken,
        refreshtoken: result?.data?.data?.refreshtoken,
      });

      if (permission) {
        // Store permissions based on user type
        // isAdminUser
        //   ? sessionStorage.setItem("per", permission)
        //   :
        localStorage.setItem("Permission", permission);

        setPermissionsLoaded(true);
      } else {
        console.error("Permission is undefined or null.");
        setPermissionsLoaded(false);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
      setPermissionsLoaded(false);
    }
  };

  const handleClearEquipmentFilter = () => {
    dispatch(removeFilterData());
    dispatch(removePayloadData());
  };

  useEffect(() => {
    if (
      location.pathname === "/accounts-listing" ||
      location.pathname === "/Admin/Sites-listing" ||
      location.pathname === "/Admin/Contact-listing" ||
      location.pathname === "/Admin/accessory-listing" ||
      location.pathname === "/Admin/Aed-listing" ||
      location.pathname === "/account/support-listing" ||
      location.pathname === "/Admin/Note-listing" ||
      location.pathname === "/account/assign-admin" ||
      location.pathname === "/account/multiple-account-permission" ||
      location.pathname === "/account/assign-create-permission" ||
      location.pathname === "/account/assign-permission-user" ||
      location.pathname === "/account/assign-permission-position" ||
      location.pathname === "/import-csv/Accounts" ||
      location.pathname === "/dashboard"
    ) {
      handleClearEquipmentFilter();
    }
    const token = localStorage.getItem("ross_token");

    // Only fetch permissions if token exists and user is not a super admin (user_type 0)
    if (token && Number(user?.user_type) !== 0) {
      fetchPermissionAPI();
    } else {
      // If no token or super admin, set permissions as loaded
      setPermissionsLoaded(true);
    }
  }, [location?.pathname]);

  //  useEffect(() => {
  //    if (location.pathname === "/accounts-listing") {
  //      handleClearEquipmentFilter();
  //    }
  //  }, [location.pathname, handleClearEquipmentFilter]);

  // Only render routes when permissions are loaded
  if (!permissionsLoaded) {
    return (
      <div
        style={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
        }}
      >
        <TableSkeleton />
      </div>
    );
  }

  return (
    <Routes>
      {/* Public Routes */}
      {PublicRoutes.map((item, i) => (
        <Route key={i} exact path={item.path} element={item.component} />
      ))}

      <Route element={<PrivateRoutes />}>
        {ProtectedRoute.map((item, i) => (
          <Route key={i} exact path={item.path} element={item.component} />
        ))}
      </Route>

      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

// Wrap the entire app with Router
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppContent />
      </Router>
    </QueryClientProvider>
  );
};

export default App;
